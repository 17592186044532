import React from "react";
import { useState } from "react";

import cloneDeep from "lodash/cloneDeep";

import ResultTable from "../components/role_assignment_report_page/ResultsTable";
import ReportFilter from "../components/role_assignment_report_page/ReportFilter";
import { Alert } from "react-bootstrap";

const RoleAssignmentsReportPage = ({
  studyId,
  initialData,
  sites,
  roles,
  roleTypes,
}) => {
  const [users, setUsers] = useState(initialData);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRoleType, setSelectedRoleType] = useState(null);

  const updateSiteSelect = (a, b) => {
    setSelectedSites(b);
  };

  const updateRoleSelect = (a, b) => {
    setSelectedRoles(b);
  };

  const updateRoleTypeSelect = (a, b) => {
    setSelectedRoleType(b);
  };

  const buildFilteredUsers = () => {
    // Is there a better way?
    let filteredUsers = cloneDeep(users);

    // Remove any users without central or site role user mappings (shouldnt ever be needed after role migration)
    filteredUsers = filteredUsers.filter((user) => {
      return (
        user.central_role_user_mappings.length > 0 ||
        user.site_role_user_mappings.length > 0
      );
    });

    // If no filters are active, we can return the filteredUsers array
    if (
      selectedSites.length == 0 &&
      selectedRoles.length == 0 &&
      selectedRoleType == null
    ) {
      return filteredUsers;
    }

    // Filter by site
    if (selectedSites.length > 0) {
      filteredUsers = filteredUsers.map((user) => {
        let filteredMappings = user.site_role_user_mappings.filter(
          (site_role_user_mapping) => {
            return selectedSites.includes(site_role_user_mapping.site.id);
          }
        );

        return {
          ...user,
          site_role_user_mappings: filteredMappings,
          central_role_user_mappings: [],
        };
      });
    }

    // Filter by role
    if (selectedRoles.length > 0) {
      filteredUsers = filteredUsers.map((user) => {
        let filteredSiteMappings = user.site_role_user_mappings.filter(
          (site_role_user_mapping) => {
            return selectedRoles.includes(site_role_user_mapping.role.id);
          }
        );

        let filteredCentralMappings = user.central_role_user_mappings.filter(
          (central_role_user_mapping) => {
            return selectedRoles.includes(central_role_user_mapping.role.id);
          }
        );

        return {
          ...user,
          site_role_user_mappings: filteredSiteMappings,
          central_role_user_mappings: filteredCentralMappings,
        };
      });
    }

    // Filter by role type
    if (selectedRoleType != null) {
      filteredUsers = filteredUsers.map((user) => {
        return {
          ...user,
          site_role_user_mappings:
            selectedRoleType == "site" ? user.site_role_user_mappings : [],
          central_role_user_mappings:
            selectedRoleType == "central"
              ? user.central_role_user_mappings
              : [],
        };
      });
    }

    return filteredUsers;
  };

  const buildCsvData = () => {
    let csvData = [];

    if (filteredUsers.length === 0) {
      return csvData;
    }

    filteredUsers.forEach((user) => {
      if (user.central_role_user_mappings.length > 0) {
        let centralRows = user.central_role_user_mappings.map((mapping) => {
          let capitalized_type =
            mapping.role.role_type == "site" ? "Site" : "Central";
          return [
            user.name,
            user.username,
            mapping.role.name,
            capitalized_type,
            "[All]",
            user.last_login_date,
          ];
        });
        csvData = [...csvData, ...centralRows];
      }

      if (user.site_role_user_mappings.length > 0) {
        let siteRows = user.site_role_user_mappings.map((mapping) => {
          let capitalized_type =
            mapping.role.role_type == "site" ? "Site" : "Central";
          return [
            user.name,
            user.username,
            mapping.role.name,
            capitalized_type,
            mapping.site.name,
            user.last_login_date,
          ];
        });
        csvData = [...csvData, ...siteRows];
      }
    });

    return csvData;
  };

  const filteredUsers = buildFilteredUsers();

  const csvData = buildCsvData();

  const filterProps = {
    studyId,
    sites,
    selectedSites,
    roles,
    selectedRoles,
    roleTypes,
    selectedRoleType,
    updateSiteSelect,
    updateRoleSelect,
    updateRoleTypeSelect,
    csvData,
  };

  const renderedOutput = () => {
    if (filteredUsers.length == 0) {
      return (
        <div className="text-muted">This study has no role assignments</div>
      );
    } else {
      return (
        <div>
          <ReportFilter {...filterProps}></ReportFilter>
          <Alert variant="warning">
            Please note we are not currently able to display the First Added
            date for users who first joined the study before July 2024.
          </Alert>
          <ResultTable users={filteredUsers}></ResultTable>
        </div>
      );
    }
  };

  return <div>{renderedOutput()}</div>;
};

export default RoleAssignmentsReportPage;
