import React, { Fragment } from "react";

import { Table } from "react-bootstrap";

const ResultTable = ({ users }) => {
  const renderedRows = users.map((user) => {
    // Create the rows for the central role user mappings
    const centralRows = user.central_role_user_mappings.map(
      (central_role_user_mapping) => {
        return (
          <tr
            id={"central_role_user_mapping_" + central_role_user_mapping.id}
            key={"site_" + central_role_user_mapping.id}
          >
            <td>{user.name}</td>
            <td>{user.username}</td>
            <td>{central_role_user_mapping.role.name}</td>
            <td className="text-capitalize">
              {central_role_user_mapping.role.role_type}
            </td>
            <td className="text-muted">[all]</td>
            <td>{user.last_login_date}</td>
            <td>{user.first_added_to_study}</td>
          </tr>
        );
      }
    );

    // Create the rows for the site role user mappings
    const siteRows = user.site_role_user_mappings.map(
      (site_role_user_mapping) => {
        return (
          <tr
            id={"site_role_user_mapping_" + site_role_user_mapping.id}
            key={"site_" + site_role_user_mapping.id}
          >
            <td>{user.name}</td>
            <td>{user.username}</td>
            <td>{site_role_user_mapping.role.name}</td>
            <td className="text-capitalize">
              {site_role_user_mapping.role.role_type}
            </td>
            <td>{site_role_user_mapping.site.name}</td>
            <td>{user.last_login_date}</td>
            <td>{user.first_added_to_study}</td>
          </tr>
        );
      }
    );

    if (siteRows.length == 0 && centralRows.length == 0) {
      return;
    }

    return (
      <Fragment key={user.id}>
        {centralRows}
        {siteRows}
      </Fragment>
    );
  });

  return (
    <Table hover bordered>
      <thead className="thead-light">
        <tr>
          <th className="w-20">Name</th>
          <th className="w-10">Username</th>
          <th className="w-20">Role</th>
          <th className="w-10">Role Type</th>
          <th className="w-20">Site</th>
          <th className="w-10">Last Login</th>
          <th className="w-10">First Added</th>
        </tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </Table>
  );
};

export default ResultTable;
